<template>
  <div
    class="container main-content"
    v-loading="loading"
    element-loading-text="正在保存，请稍等。。。"
    element-loading-spinner="el-icon-loading"
  >
    <div class="crt-content">
      <div class="crt-content-left">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-edit"
          class="commonBtn"
          @click="changeBasicInfo"
        >
          {{ isResearch ? '编辑' : '取消' }}
        </el-button>
        <el-button
          v-if="!isResearch"
          :disabled="btnDisabled"
          :type="type"
          size="mini"
          icon="el-icon-edit"
          class="commonBtn"
          @click="saveBasicInfo"
        >
          保存
        </el-button>
      </div>
      <div class="crt-content-right">
        <template
          v-if="(tabs[this.activeName]?.alias || tabs[this.activeName]?.formName) !== '访视状态'"
        >
          <el-button size="mini" icon="el-icon-plus" @click="handleLeading('auto')">
            自动引入
          </el-button>
          <el-button size="mini" icon="el-icon-plus" @click="handleLeading">导入LIS</el-button>
          <el-button size="mini" icon="el-icon-plus" @click="handlePACS">导入检查报告</el-button>
          <el-button size="mini" icon="el-icon-plus" @click="handleUpPACS">上传本地PDF</el-button>
        </template>
        <el-button size="mini" icon="el-icon-tickets" @click="getIntelligentComparisonBefore">
          出院前比对
        </el-button>
        <el-button size="mini" icon="el-icon-tickets" @click="getIntelligentComparison">
          随访记录比对
        </el-button>
        <el-upload
          ref="upload"
          style="display: none"
          action=""
          :show-file-list="false"
          :http-request="pdfImport"
          multiple
        >
          <el-button size="mini" type="primary">上传本地PDF</el-button>
        </el-upload>
      </div>
    </div>
    <el-card class="body">
      <div class="info-wrap">
        <div class="content">
          <template v-if="followObj.length">
            <div class="edit-form">
              <div class="base-form">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane
                    v-for="(item, index) in tabs"
                    :key="item.alias || item.formName"
                    :label="getLabel(item)"
                    :name="String(index)"
                  >
                    <div class="content-box" :ref="'tab' + index" v-if="item.alias === '访视状态'">
                      <div class="component">
                        <div class="list">
                          <div class="basic-div">
                            <div class="select-title">是否完成随访：</div>
                            <div class="radio">
                              <el-radio-group
                                :class="
                                  visitsStatus.isSucceed &&
                                  visitsStatus.isSucceed !== isEditInfo.isSucceed
                                    ? 'radioEditStyle'
                                    : ''
                                "
                                v-model="visitsStatus.isSucceed"
                              >
                                <template>
                                  <el-radio
                                    v-for="ele in isSucceedList"
                                    :key="ele.id"
                                    :label="ele.title"
                                    @click.native.prevent="selectRadio(ele, 'isSucceed')"
                                    border
                                    :disabled="isReadonly"
                                  >
                                    {{ ele.title }}
                                  </el-radio>
                                </template>
                              </el-radio-group>
                            </div>
                          </div>
                          <div class="deep"></div>
                          <div
                            class="basic-div"
                            v-if="visitsStatus.isSucceed && visitsStatus.isSucceed === '否'"
                          >
                            <div class="select-title">未完成随访原因：</div>
                            <div class="radio">
                              <el-radio-group
                                :class="
                                  visitsStatus.incompleteReason &&
                                  visitsStatus.incompleteReason !== isEditInfo.incompleteReason
                                    ? 'radioEditStyle'
                                    : ''
                                "
                                v-model="visitsStatus.incompleteReason"
                              >
                                <template>
                                  <el-radio
                                    v-for="ele in incompleteReasonList"
                                    :key="ele.id"
                                    :label="ele.title"
                                    @click.native.prevent="selectRadio(ele, 'incompleteReason')"
                                    border
                                    :disabled="isReadonly"
                                  >
                                    {{ ele.title }}
                                  </el-radio>
                                </template>
                              </el-radio-group>
                            </div>
                          </div>
                          <div class="deep"></div>
                          <div
                            class="basic-div"
                            v-if="
                              visitsStatus.incompleteReason &&
                              visitsStatus.incompleteReason === '死亡'
                            "
                          >
                            <div class="select-title">死亡时间：</div>
                            <div class="radio">
                              <el-date-picker
                                :class="
                                  visitsStatus.deathDate &&
                                  visitsStatus.deathDate !== isEditInfo.deathDate
                                    ? 'editStyle'
                                    : ''
                                "
                                v-model="visitsStatus.deathDate"
                                type="date"
                                placeholder="选择日期"
                                clearable
                                align="center"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                :disabled="isReadonly"
                              ></el-date-picker>
                            </div>
                          </div>
                          <div class="deep"></div>
                          <div class="basic-div">
                            <div class="select-title">随访时间：</div>
                            <div class="radio">
                              <el-date-picker
                                :class="
                                  visitsStatus.followDate &&
                                  visitsStatus.followDate !== isEditInfo.followDate
                                    ? 'editStyle'
                                    : ''
                                "
                                v-model="visitsStatus.followDate"
                                type="date"
                                placeholder="选择日期"
                                clearable
                                align="center"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                :disabled="isReadonly"
                              ></el-date-picker>
                            </div>
                          </div>
                          <div class="deep"></div>
                          <div class="basic-div">
                            <div class="select-title">随访类型：</div>
                            <div class="radio">
                              <el-radio-group
                                :class="
                                  visitsStatus.followType &&
                                  visitsStatus.followType !== isEditInfo.followType
                                    ? 'radioEditStyle'
                                    : ''
                                "
                                v-model="visitsStatus.followType"
                              >
                                <template>
                                  <el-radio
                                    v-for="ele in followTypeList"
                                    :key="ele.id"
                                    :label="ele.title"
                                    @click.native.prevent="selectRadio(ele, 'followType')"
                                    border
                                    :disabled="isReadonly"
                                  >
                                    {{ ele.title }}
                                  </el-radio>
                                </template>
                              </el-radio-group>
                            </div>
                          </div>
                          <div class="deep"></div>
                        </div>
                      </div>
                    </div>
                    <template v-else>
                      <readonly-tab
                        :isReadonly="isReadonly"
                        :ref="'tab' + index"
                        :info="info"
                        :tabInfo="{ ...item, ...dataList[Number(index)] }"
                        :followId="followObj[Number(uuid)].dataId"
                        :data="dataList[Number(index)]?.content"
                        :dataUuid="handleItemUuid"
                        :examinationInfo="dataList[Number(index)]?.examinationInfo"
                        :loading="loading"
                        @closeLoading="isLoading"
                        @save="saveEFormInfo"
                        @handleDownload="handleDownloadPdf"
                        @delExamine="delExamine"
                      />
                    </template>
                  </el-tab-pane>
                </el-tabs>
                <el-dialog
                  width="30%"
                  title="检查类型选择"
                  class="add-pacs"
                  :visible.sync="pacsTypeInnerVisible"
                >
                  <div class="">
                    <span>请选择检查类型：</span>
                    <el-select
                      v-model="PACSTypeCode"
                      placeholder="请选择"
                      @change="handlePACSTemplet"
                    >
                      <el-option
                        v-for="item in PACSOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-dialog>
                <el-dialog
                  width="30%"
                  title="表单引入"
                  class="add-form auto-leading"
                  :visible.sync="innerVisibleAutoLeading"
                >
                  <div class="dialog-btn-wrap">
                    <template>
                      <el-checkbox v-model="syncFirstStatus" style="margin-right: 10px">
                        查询前先同步LIS
                      </el-checkbox>
                      <el-button
                        type="primary"
                        size="mini"
                        class="commonBtn"
                        @click="autoLeading"
                        icon="el-icon-search"
                      >
                        确认
                      </el-button>
                    </template>
                  </div>
                </el-dialog>

                <el-dialog
                  width="60%"
                  title="表单引入"
                  class="add-form"
                  :visible.sync="innerVisible"
                >
                  <div class="dialog-btn-wrap">
                    <template>
                      按日期查询：
                      <el-date-picker
                        v-model="dialogQueryDate"
                        type="daterange"
                        align="center"
                        size="mini"
                        style="margin-right: 10px"
                        unlink-panels
                        @change="handleDialogQueryDate"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                      <el-checkbox v-model="syncFirstStatus" style="margin-right: 10px">
                        查询前先同步LIS
                      </el-checkbox>
                      <el-button
                        type="primary"
                        size="mini"
                        class="commonBtn"
                        @click="fetchExamineData"
                        icon="el-icon-search"
                      >
                        查询
                      </el-button>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="saveOrAdviceList"
                        icon="el-icon-s-promotion"
                        class="commonBtn"
                      >
                        引入
                      </el-button>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="allSaveOrAdviceList"
                        icon="el-icon-s-promotion"
                        class="commonBtn"
                      >
                        全部引入
                      </el-button>
                    </template>
                  </div>
                  <div class="form-wrap" v-if="queryExamOrAdviceList.length > 0">
                    <div class="adviceTable">
                      <div class="adviceTableLabel">
                        <div class="adviceTableHeader"></div>
                        <div
                          class="adviceTableLabelItem"
                          v-for="(ele, index) in columnHeaderList"
                          :key="'title' + index"
                        >
                          {{ ele.itemTitle }}{{ ele.itemUnit }}
                        </div>
                      </div>
                      <div class="adviceTablePreviewLabel">
                        <div class="adviceTableHeader">预览</div>
                        <div
                          class="adviceTableLabelItem"
                          v-for="(ele, index) in columnPreviewList"
                          :key="'title' + index"
                        >
                          {{ ele.itemValue }}
                        </div>
                      </div>
                      <div class="adviceTableBox">
                        <div
                          class="adviceTableContent"
                          v-for="(column, columnIndex) in queryExamOrAdviceList"
                          :key="columnIndex"
                        >
                          <div
                            @click="selectColumns(columnIndex)"
                            class="adviceTableHeader"
                            :class="column.defaultHighlight ? 'queryExamToday' : ''"
                          >
                            {{ column.defaultHighlight ? '✔️' : '' }}
                            {{ column.examineDate.substr(0, 16) }}
                          </div>
                          <div
                            class="adviceTableContentItem"
                            v-for="(cell, cellIndex) in column?.items"
                            :key="cellIndex + cell.uuid"
                            :class="{ 'cell-pitch_on': cell._isSecect }"
                            @click="handleItem(column, columnIndex, cell)"
                          >
                            {{ cell.itemValue }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <el-empty style="margin-top: 40px" v-else description="暂无数据" />
                </el-dialog>
                <el-dialog
                  width="60%"
                  title="检查报告导入"
                  class="add-form"
                  :visible.sync="PACSinnerVisible"
                >
                  <div class="dialog-btn-wrap">
                    <template>
                      按日期查询：
                      <el-date-picker
                        v-model="dialogQueryDate"
                        type="daterange"
                        align="center"
                        size="mini"
                        style="margin-right: 10px"
                        unlink-panels
                        @change="handleDialogQueryDate"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                      <el-checkbox v-model="syncFirstStatus" style="margin-right: 10px">
                        查询前同步先检查报告
                      </el-checkbox>
                      <el-button
                        type="primary"
                        size="mini"
                        class="commonBtn"
                        @click="fetchPACSExamineData"
                        icon="el-icon-search"
                      >
                        查询
                      </el-button>
                    </template>
                  </div>
                  <div class="form-wrap" v-if="PACSExamineData.length > 0">
                    <el-table
                      :data="PACSExamineData"
                      class="PACSExamineTable"
                      border
                      :header-cell-style="{
                        background: '#eef1f6',
                        color: '#606266',
                        fontSize: '15px'
                      }"
                      height="100%"
                    >
                      <el-table-column
                        prop="examineType"
                        align="center"
                        fixed="left"
                        label="检查报告"
                        width="180"
                      >
                        <template slot-scope="scope">
                          <div>{{ scope.row.examineType }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="description"
                        align="center"
                        label="检查结果"
                        show-overflow-tooltip
                        min-width="180"
                      >
                        <template slot-scope="scope">
                          <div>{{ scope.row.description }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="examineDate"
                        align="center"
                        label="检查时间"
                        width="150"
                      ></el-table-column>
                      <el-table-column align="center" label="操作" width="120">
                        <template slot-scope="scope">
                          <el-button
                            type="primary"
                            size="mini"
                            @click="savePACSOrAdviceList(scope.row)"
                            icon="el-icon-s-promotion"
                          >
                            引入
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <el-empty v-else style="margin-top: 40px" description="暂无数据" />
                </el-dialog>
              </div>
            </div>
          </template>
          <div v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import ReadonlyTab from './components/ReadonlyTab.vue'
import deepClone from '../../../plugins/clone'
import { uniqueJsonArrByField, getBeforeDate, getLatestExamineData } from '../../../utils'
export default {
  components: {
    ReadonlyTab
  },
  props: ['followObj', 'info'],
  data() {
    return {
      activeName: '0',
      lastIndex: '0',
      isReadonly: true,
      isResearch: true,
      dataList: [], //编辑表单数据项
      uuid: '0',
      tabs: [],
      innerVisibleAutoLeading: false,
      innerVisible: false,
      PACSinnerVisible: false,
      pacsTypeInnerVisible: false,
      syncFirstStatus: true,
      PACSOptions: [],
      PACSTypeCode: '',
      queryExamOrAdviceList: [],
      columnHeaderList: [], //列头
      columnPreviewList: [], // 预览
      dialogQueryDate: null,
      dialogQueryList: null,
      visitsStatus: {
        isSucceed: null,
        deathDate: null,
        incompleteReason: null,
        followDate: null,
        followType: null
      },
      isEditInfo: {
        isSucceed: null,
        deathDate: null,
        incompleteReason: null,
        followDate: null,
        followType: null
      },
      isSucceedList: [
        { id: 1, title: '是' },
        { id: 2, title: '否' }
      ],
      incompleteReasonList: [
        { id: 1, title: '死亡' },
        { id: 2, title: '失访' },
        { id: 3, title: '其他' }
      ],
      followTypeList: [
        { id: 1, title: '本院' },
        { id: 2, title: '外院' },
        { id: 3, title: '上门' },
        { id: 4, title: '电话' }
      ],
      examineId: null,
      // 导入日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      handleItemUuid: [],
      checkDataId: '',
      btnDisabled: false,
      type: 'primary',
      loading: false,
      PACSExamineData: [],
      scrollTrigger: false, // 默认初始值
      isLock: false,
      handleItemList: [],
      baseFollowId: undefined
    }
  },
  watch: {
    // 切换不同 uuid
    uuid: {
      handler(val) {
        if (val || val === 0) {
          if (this.followObj.length) {
            if (
              this.followObj[Number(this.uuid)].dataId ||
              this.followObj[Number(this.uuid)].menuCode
            ) {
              if (this.followObj[Number(this.uuid)].dataId) {
                this.getFollowInfo()
                this.getLoadEFormDataList(
                  this.followObj[Number(this.uuid)].dataId,
                  'int',
                  'initData'
                )
              } else {
                this.getLoadEFormDataList(this.followObj[Number(this.uuid)].menuCode, 'int', 'init')
              }
            }
          }
        }
      }
    },
    '$route.query.stage'(val) {
      this.followObj.forEach((item, index) => {
        if (item.menuTitle === val) {
          this.uuid = index
        }
      })
      this.activeName = '0'
      this.lastIndex = '0'
    },
    // 刷新页面时
    followObj(val) {
      if (val && val.length) {
        val.forEach((item, index) => {
          if (item.menuTitle === this.$route.query.stage) {
            if (index === 0) {
              if (val[Number(this.uuid)].dataId || val[Number(this.uuid)].menuCode) {
                if (this.isLock === false) {
                  if (val[Number(this.uuid)].dataId) {
                    this.getFollowInfo()
                    this.getLoadEFormDataList(val[Number(this.uuid)].dataId, 'int', 'initData')
                  } else {
                    this.getLoadEFormDataList(val[Number(this.uuid)].menuCode, 'int', 'init')
                  }
                }
              }
            } else {
              this.uuid = index
            }
          }
        })
      }
    },
    isResearch: {
      handler(val) {
        if (val) {
          this.isReadonly = true
        } else {
          this.isReadonly = false
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.getUid()
    this.getPACSTypes()
  },
  methods: {
    getUid() {
      for (let i = 0; i < this.followObj.length; i++) {
        const element = this.followObj[i]
        if (element.menuTitle === this.$route.query.stage) {
          this.uuid = i
        }
      }
    },

    getFollowInfo() {
      this.$api
        .get(`/v1/webconsole/follow/${this.followObj[Number(this.uuid)].dataId}`)
        .then(({ data: res }) => {
          if (res && res.data) {
            this.visitsStatus = res.data
            this.isEditInfo = deepClone(this.visitsStatus)
          }
        })
    },
    getLabel(val) {
      if (val.alias) {
        return (
          val.alias + ' ' + (val.completion && val.completion !== 0 ? val.completion + '%' : '')
        )
      } else {
        return (
          val.formName + ' ' + (val.completion && val.completion !== 0 ? val.completion + '%' : '')
        )
      }
    },

    scrollToTop() {
      if (this.scrollTrigger) {
        return
      }
      const container = document.querySelector('.body') // 选择合适的父元素
      if (container.scrollTop > 0) {
        let scrollTop = container.scrollTop
        let steep = scrollTop / 2000
        let timer = setInterval(() => {
          this.scrollTrigger = true
          // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
          scrollTop -= steep
          // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
          steep += 20
          if (scrollTop <= 0) {
            clearInterval(timer)
            this.scrollTrigger = false
          }
          container.scrollTop = scrollTop
        }, 20)
      }
    },
    // 上传文件
    onUpload(formData) {
      if (this.followObj[Number(this.uuid)].dataId) {
        this.$api
          .post(
            `/v1/webconsole/examination/import/follow/${this.followObj[Number(this.uuid)].dataId}`,
            formData
          )
          .then(({ data: res }) => {
            if (res && res.data) {
              this.isResearch = false
              let newArr = JSON.parse(
                JSON.stringify(this.dataList[Number(this.activeName)].content)
              )
              newArr.map((item) => {
                res.data.newForm.map((items) => {
                  if (item.id === items.id) {
                    if (items.value) {
                      item.value = items.value
                    }
                  }
                })
              })
              this.dataList[Number(this.activeName)].content = newArr
            }
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.examineId = res.data.examine.uuid
            this.dataList[Number(this.activeName)].examinationInfo.push(res.data.examine)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    delExamine(val) {
      let formData = new FormData()
      formData.append('examineId', val)
      this.$api
        .post(
          `/v1/webconsole/eform/delete/formdata/examine/${this.dataList[this.activeName].id}`,
          formData
        )
        .then(() => {
          this.getExamination()
        })
    },
    handleDownloadPdf() {
      this.$api
        .get(`/v1/webconsole/examination/eform/examination/${this.dataList[this.activeName].id}`)
        .then(({ data: res }) => {
          if (res && res.data && res.data[0]?.sourcePath) {
            // this.axios
            //   .get(`/v1/webconsole/nurseHandover/preview?filename=${res.data[0].sourcePath}`, {
            //     responseType: 'blob'
            //   })
            //   .then((res) => {
            //     if (res.data && res.data.size !== 0) {
            //       let blob = new Blob([res.data], {
            //         //type类型后端返回来的数据中会有，根据自己实际进行修改
            //         type: 'application/pdf'
            //       })
            //       let filename = this.tabs[this.activeName].alias + '.pdf'
            //       if (typeof window.navigator.msSaveBlob !== 'undefined') {
            //         window.navigator.msSaveBlob(blob, filename)
            //       } else {
            //         var blobURL = window.URL.createObjectURL(blob)
            //         // 创建隐藏<a>标签进行下载
            //         var tempLink = document.createElement('a')
            //         tempLink.style.display = 'none'
            //         tempLink.href = blobURL
            //         tempLink.setAttribute('download', filename)
            //         if (typeof tempLink.download === 'undefined') {
            //           tempLink.setAttribute('target', '_blank')
            //         }
            //         document.body.appendChild(tempLink)
            //         tempLink.click()
            //         document.body.removeChild(tempLink)
            //         window.URL.revokeObjectURL(blobURL)
            //       }
            //     }
            //   })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getExamination() {
      this.$api
        .get(`/v1/webconsole/examination/eform/examination/${this.dataList[this.activeName].id}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.dataList[Number(this.activeName)].examinationInfo = res.data.data
          } else {
            this.dataList[Number(this.activeName)].examinationInfo = []
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 导入pdf
    pdfImport(file) {
      let formData = new FormData()
      formData.append('formId', this.tabs[Number(this.activeName)].formId)
      formData.append('moduleCode', this.$route.query.moduleCode)
      formData.append('examTypeCode', this.PACSTypeCode)
      formData.append('file', file.file)
      this.onUpload(formData)
    },
    getPACSTypes() {
      this.axios.get('/v1/webconsole/examination/list/examine_types').then(({ data: res }) => {
        if (res && res.data && res.data.length > 0) {
          let arr = []
          res.data.map((item) => {
            arr.push({
              label: item.examineTypeName,
              value: item.examineTypeCode
            })
          })
          this.PACSOptions = arr
        }
      })
    },
    handlePACSTemplet() {
      this.$refs['upload'].$refs['upload-inner'].handleClick()
      this.pacsTypeInnerVisible = false
    },
    handleUpPACS() {
      this.PACSTypeCode = ''
      this.pacsTypeInnerVisible = true
    },
    handlePACS() {
      var date
      if (this.visitsStatus.followDate) {
        date = new Date(this.visitsStatus.followDate)
      } else {
        date = new Date()
      }
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const todayDate = `${year}-${month}-${day}`

      var timestamp = date.getTime() // 获取时间戳

      // 减去 7 天的毫秒数
      var subtractedTimestamp = timestamp - 7 * 24 * 60 * 60 * 1000

      var subtractedDate = new Date(subtractedTimestamp) // 创建新的 Date 对象
      var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      var formattedDate = subtractedDate.toLocaleDateString('zh-CN', options).replace(/\//g, '-')
      this.dialogQueryDate = [formattedDate, this.visitsStatus.followDate || todayDate]
      this.dialogQueryList = this.dialogQueryDate.join(',')
      this.PACSExamineData = []
      this.syncFirstStatus = true

      this.PACSinnerVisible = true
    },
    fetchPACSExamineData() {
      let examineTypeCode = this.tabs[Number(this.activeName)].examineTypeCode
      let formData = {
        startDate: this.dialogQueryDate[0],
        stopDate: this.dialogQueryDate[1],
        examineTypeCode: examineTypeCode,
        syncFirst: this.syncFirstStatus ? true : false
      }
      this.$api
        .get(`/v1/webconsole/examination/patient/pacs/${this.info.patientsIndex}`, formData)
        .then(({ data: res }) => {
          if (res && res.data) {
            this.PACSExamineData = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    savePACSOrAdviceList(val) {
      if (this.info.patientsID) {
        this.axios
          .post(
            `/v1/webconsole/examination/import/examine/${val.uuid}?formId=${
              this.tabs[this.activeName].formId
            }`
          )
          .then(({ data: res }) => {
            if (res && res.data) {
              this.isResearch = false
              this.PACSinnerVisible = false
              let newArr = JSON.parse(
                JSON.stringify(this.dataList[Number(this.activeName)].content)
              )
              newArr.map((item) => {
                res.data.newForm.map((items) => {
                  if (item.id === items.id) {
                    if (items.value) {
                      item.value = items.value
                    }
                  }
                })
              })
              this.dataList[Number(this.activeName)].content = newArr
            }
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.examineId = res.data.examine.uuid
            this.dataList[Number(this.activeName)].examinationInfo.push(res.data.examine)
          })
      }
    },
    uniqueJsonArrByField,
    // 实验室数据引入
    autoLeading() {
      let formData = new FormData()
      if (this.dataList[this.activeName].id) {
        formData.append('formDataId', this.dataList[this.activeName].id)
      }
      if (this.syncFirstStatus) {
        formData.append('syncFirst', true)
      }
      this.$api
        .post(
          `/v1/webconsole/follow/import/examination/${this.followObj[Number(this.uuid)].dataId}/${
            this.tabs[this.activeName].formId
          }`,
          formData
        )
        .then(({ data: res }) => {
          if (res && res.data) {
            const elements = res.data.elements
            res.data.examinationIds.forEach((item) => {
              if (!this.handleItemUuid.includes(item)) {
                this.handleItemUuid.push(item)
              }
            })
            let newArr = JSON.parse(JSON.stringify(this.dataList[Number(this.activeName)].content))
            newArr.map((item) => {
              item.elementId = item.id
              elements?.map((ele) => {
                if (item.id === ele.id) {
                  item.value = ele.value
                }
              })
            })
            this.dataList[Number(this.activeName)].isSave = false
            this.dataList[Number(this.activeName)].newTab = JSON.parse(
              JSON.stringify(this.dataList[Number(this.activeName)].content)
            )
            setTimeout(() => {
              this.dataList[Number(this.activeName)].content = newArr
            }, 200)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      this.isResearch = false
      this.isReadonly = false
      this.innerVisibleAutoLeading = false
    },
    handleLeading(type) {
      var date
      if (this.visitsStatus.followDate) {
        date = new Date(this.visitsStatus.followDate)
      } else {
        date = new Date()
      }
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const todayDate = `${year}-${month}-${day}`

      var timestamp = date.getTime() // 获取时间戳

      // 减去 7 天的毫秒数
      var subtractedTimestamp = timestamp - 7 * 24 * 60 * 60 * 1000

      var subtractedDate = new Date(subtractedTimestamp) // 创建新的 Date 对象
      var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      var formattedDate = subtractedDate.toLocaleDateString('zh-CN', options).replace(/\//g, '-')
      this.dialogQueryDate = [formattedDate, this.visitsStatus.followDate || todayDate]
      this.dialogQueryList = this.dialogQueryDate.join(',')
      this.syncFirstStatus = true
      this.queryExamOrAdviceList = []
      this.handleItemUuid = []
      this.handleItemList = []
      if (type === 'auto') {
        this.innerVisibleAutoLeading = true
      } else {
        this.innerVisible = true
        this.fetchExamineData()
      }
    },

    // 查询导入数据
    fetchExamineData() {
      let arr = this.tabs[Number(this.activeName)]
      if (this.dialogQueryList) {
        this.$api
          .get(`/v1/webconsole/examination/operation/lis/${this.info.patientsIndex}`, {
            startDate: this.dialogQueryDate[0],
            stopDate: this.dialogQueryDate[1],
            formId: arr.formId,
            menuCode: 'follow',
            businessId: this.followObj[Number(this.uuid)].dataId,
            syncFirst: this.syncFirstStatus ? true : false
          })
          .then((res) => {
            if (res.data && res.data.data) {
              // res.data.data.sort(
              //   (b, a) => new Date(a.examineDate).getTime() - new Date(b.examineDate).getTime()
              // ) //时间升序
              this.queryExamOrAdviceList = res.data.data
              this.queryExamOrAdviceList.forEach((item) =>
                item.items.forEach((ite) => (ite._isSecect = false))
              )
              let newArr = []
              res.data.data.map((item) => {
                item.items.map((ele) => {
                  newArr.push(ele) // 取出所有的子项
                })
              })
              // 处理数据 保持同一长度
              this.columnHeaderList = this.uniqueJsonArrByField(newArr, 'itemTitle') || [] // 去重
              res.data.data.map((item) => {
                let newColumnHeaderList = JSON.parse(JSON.stringify(this.columnHeaderList))
                const newList = newColumnHeaderList.map((ele) => {
                  ele.itemValue = null
                  ele.uuid = parseInt(Math.random(0, 1) * 1000000)
                  item.items.map((thirdItem) => {
                    if (ele.itemTitle === thirdItem.itemTitle) {
                      ele = { ...ele, ...thirdItem }
                    }
                  })
                  return ele
                })
                item.items = newList
              })
              setTimeout(() => {
                this.columnPreviewList = JSON.parse(JSON.stringify(this.columnHeaderList))
                this.queryExamOrAdviceList.forEach((ele, index) => {
                  this.initColumns(index)
                })
              }, 200)
              this.isResearch = false
            }
          })
      } else {
        this.$message.error('日期不能为空')
      }
    },
    // 日期更改
    handleDialogQueryDate() {
      if (!this.dialogQueryDate) {
        this.dialogQueryList = null
      } else {
        this.dialogQueryList = this.dialogQueryDate.join(',')
      }
    },
    getParentValue(val, arr) {
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i]
        if (val.parentId === element.id) {
          if (val.parentValue) {
            element.value = val.parentValue
          }
        }
      }
    },
    async allSaveOrAdviceList() {
      let url = `/v1/webconsole/examination/import/lis/${this.info.patientsIndex}`
      let formData = new FormData()
      formData.append('formId', this.tabs[this.activeName].formId)
      formData.append('menuCode', 'follow')
      formData.append('startDate', this.dialogQueryDate[0])
      formData.append('stopDate', this.dialogQueryDate[1])

      await this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data && res.data.elements) {
          const elements = res.data.elements
          res.data.examinationIds.forEach((item) => {
            if (!this.handleItemUuid.includes(item)) {
              this.handleItemUuid.push(item)
            }
          })
          let newArr = JSON.parse(JSON.stringify(this.dataList[Number(this.activeName)].content))
          newArr.map((item) => {
            item.elementId = item.id
            elements?.map((ele) => {
              if (item.id === ele.id) {
                item.value = ele.value
              }
            })
          })
          this.dataList[Number(this.activeName)].isSave = false
          this.dataList[Number(this.activeName)].newTab = JSON.parse(
            JSON.stringify(this.dataList[Number(this.activeName)].content)
          )
          setTimeout(() => {
            this.dataList[Number(this.activeName)].content = newArr
          }, 200)
        }
      })
      this.isResearch = false
      this.isReadonly = false
      this.innerVisible = false
    },
    async saveOrAdviceList() {
      this.handleItemList = getLatestExamineData(this.queryExamOrAdviceList)
      let newArr = JSON.parse(JSON.stringify(this.dataList[Number(this.activeName)].content))
      newArr.map((item) => {
        item.elementId = item.id
        this.handleItemList?.map((items) => {
          if (item.elementId === items.elementId) {
            if (items.itemValue) {
              item.value = items.itemValue
            }
            if (item.parentId !== '-1') {
              this.getParentValue(item, newArr)
            }
          }
        })
      })
      this.dataList[Number(this.activeName)].isSave = false
      this.dataList[Number(this.activeName)].newTab = JSON.parse(
        JSON.stringify(this.dataList[Number(this.activeName)].content)
      )
      setTimeout(() => {
        this.dataList[Number(this.activeName)].content = newArr
      }, 200)
      if (this.handleItemUuid.length > 0) {
        const newArrId = []
        this.handleItemUuid.forEach((item) => {
          if (!newArrId.includes(item)) {
            newArrId.push(item)
          }
        })
        if (!this.dataList[this.activeName].id) {
          let arr = JSON.parse(JSON.stringify(this.dataList[Number(this.activeName)].content))
          let dataList = {
            items: arr,
            examineIds: newArrId
          }
          dataList.items.forEach((item) => {
            item.elementId = item.id
            if (item.type === 'checkbox') {
              if (item.value?.length > 0) {
                item.value = item.value?.join(',')
              } else {
                item.value = null
              }
            }
          })
          let url = `/v1/webconsole/eform/follow/save/${
            this.followObj[Number(this.uuid)].dataId || this.followObj[Number(this.uuid)].menuCode
          }`
          let data = {
            formId: '',
            id: '',
            ...dataList
          }
          data.formId = this.tabs[Number(this.activeName)].formId
          await this.$api.post(url, data).then((res) => {
            this.dataList[Number(this.activeName)].id = res.data.data.id
            if (res.data && res.data.data) {
              this.$api
                .get(`/v1/webconsole/examination/eform/examination/${res.data.data.id}`)
                .then((res) => {
                  if (res.data && res.data.data) {
                    this.dataList[Number(this.activeName)].examinationInfo = res.data.data
                  } else {
                    this.dataList[Number(this.activeName)].examinationInfo = []
                  }
                })
            }
          })
        }
      }
      this.isResearch = false
      this.isReadonly = false
      this.innerVisible = false
    },
    // handleItem(column, columnIndex, cell) {
    //   cell._isSecect = !cell._isSecect
    //   this.queryExamOrAdviceList.forEach((columns, i) => {
    //     if (columnIndex !== i) {
    //       columns.items.forEach((ele) => {
    //         if (cell.itemTitle === ele.itemTitle) {
    //           ele._isSecect = false
    //           if (
    //             ele.itemValue &&
    //             new Date(columns.examineDate) - new Date(column.examineDate) > 0 &&
    //             ele._isSecect
    //           ) {
    //             cell._isSecect = !cell._isSecect
    //           } else if (ele.itemValue && !cell.itemValue && cell._isSecect) {
    //             cell._isSecect = false
    //             ele._isSecect = true
    //           } else {
    //             ele._isSecect = false
    //           }
    //         }
    //       })
    //     }
    //   })

    //   this.handleItemUuid = column.items
    //     .filter((cell) => cell._isSecect)
    //     .map((cell) => cell.examinationId)

    //   this.$forceUpdate()
    // },

    handleItem(column, columnIndex, cell) {
      if (cell.itemValue) {
        cell._isSecect = !cell._isSecect
      }
      this.handleItemUuid = []

      this.queryExamOrAdviceList.forEach((columns, i) => {
        if (columnIndex !== i) {
          columns.items.forEach((ele) => {
            if (cell.itemTitle === ele.itemTitle && cell.itemValue) {
              ele._isSecect = false

              if (ele.itemValue) {
                if (
                  new Date(columns.examineDate) - new Date(column.examineDate) > 0 &&
                  ele._isSecect
                ) {
                  cell._isSecect = !cell._isSecect
                } else if (!cell.itemValue && cell._isSecect) {
                  cell._isSecect = false
                  ele._isSecect = true
                }
              }
            }
          })
        }
        this.handleItemUuid.push(
          ...columns.items.filter((cell) => cell._isSecect).map((cell) => cell.examinationId)
        )
      })

      this.columnPreviewList.forEach((item, index) => {
        if (item.itemTitle === cell.itemTitle && cell.itemValue) {
          const clonedItem = { ...cell }
          if (!cell._isSecect) {
            clonedItem.itemValue = null
          }
          this.$set(this.columnPreviewList, index, clonedItem)
        }
      })

      this.$forceUpdate()
    },

    selectColumns(i) {
      this.queryExamOrAdviceList.forEach((cln, cellIndex) => {
        cln.items.forEach((cell) => {
          if (i === cellIndex) {
            if (cell.itemValue) {
              this.handleItem(cln, i, cell)
            } else {
              cell._isSecect = false
            }
          }
        })
      })
      this.$forceUpdate()
    },

    initColumns(index) {
      const column = this.queryExamOrAdviceList[index]

      column.items.forEach((cell) => {
        if (cell.itemValue) {
          let shouldSelect = true
          this.queryExamOrAdviceList.forEach((otherColumn, otherIndex) => {
            if (index !== otherIndex) {
              otherColumn.items.forEach((otherCell) => {
                if (cell.itemTitle === otherCell.itemTitle && otherCell._isSecect) {
                  shouldSelect = false
                }
              })
            }
          })

          if (shouldSelect) {
            cell._isSecect = true
            this.columnPreviewList.forEach((item, index) => {
              if (item.itemTitle === cell.itemTitle) {
                item.itemValue = cell.itemValue
              }
            })
          }
        }
      })
      this.handleItemUuid.push(
        ...column.items.filter((cell) => cell._isSecect).map((cell) => cell.examinationId)
      )
      this.$forceUpdate()
    },
    handleClick() {
      this.handleItemUuid = []
      this.examineId = null
      this.isResearch = true
      if (this.dataList[this.activeName].id) {
        this.getExamination()
      } else {
        this.dataList[Number(this.activeName)].examinationInfo = []
      }
      if (this.lastIndex === '0') {
        this.visitsStatus = deepClone(this.isEditInfo)
      } else {
        if (this.dataList[this.lastIndex].isSave === false) {
          this.dataList[Number(this.lastIndex)].content = JSON.parse(
            JSON.stringify(this.dataList[Number(this.lastIndex)].newTab)
          )
        }
        eval('this.$refs.tab' + this.lastIndex)[0].isEdit()
      }
      this.lastIndex = this.activeName
    },
    changeBasicInfo() {
      this.btnDisabled = false
      this.type = 'primary'
      if (!this.tabs[Number(this.activeName)]?.templateId) {
        this.isResearch = !this.isResearch
        if (this.dataList[this.activeName].id) {
          this.getExamination()
        } else {
          this.dataList[Number(this.activeName)].examinationInfo = []
        }
        this.handleItemUuid = []
        if (this.tabs[Number(this.activeName)]?.formId && this.lastIndex !== '0') {
          eval('this.$refs.tab' + this.activeName)[0].isEdit()
        } else if (this.lastIndex === '0') {
          this.visitsStatus = deepClone(this.isEditInfo)
        }
      } else if (this.tabs[Number(this.activeName)].alias === '访视状态') {
        this.isResearch = !this.isResearch
        this.visitsStatus = deepClone(this.isEditInfo)
      }
    },
    saveEFormInfo(val) {
      val.items.forEach((item) => {
        item.elementId = item.id
      })
      let url = `/v1/webconsole/eform/follow/save/${
        this.followObj[Number(this.uuid)].dataId || this.followObj[Number(this.uuid)].menuCode
      }`
      let data = {
        formId: '',
        id: '',
        ...val
      }
      if (this.examineId) {
        data.examineIds.push(this.examineId)
      }
      if (this.dataList[Number(this.activeName)]?.id) {
        data.formId = this.dataList[Number(this.activeName)].formId
        data.id = this.dataList[Number(this.activeName)].id
      } else {
        data.formId = this.tabs[Number(this.activeName)].formId
      }

      this.$api.post(url, data).then(
        (res) => {
          this.isResearch = true
          this.isLock = true
          this.type = 'primary'
          this.$message.success(res.data.msg)
          this.dataList[this.activeName].isSave === true
          if (this.followObj[Number(this.uuid)].dataId) {
            this.getLoadEFormDataList(this.followObj[Number(this.uuid)].dataId, 'edit', 'initData')
          } else {
            this.getLoadEFormDataList(this.followObj[Number(this.uuid)].menuCode, 'edit', 'init')
          }
          this.scrollToTop()
          this.$bus.$emit('saveFollowFormInfo')
          this.$bus.$emit('getUserFollowInfo')
        },
        () => {
          this.type = 'primary'
          this.btnDisabled = false
          this.loading = false
        }
      )
    },
    isLoading() {
      this.type = 'primary'
      this.btnDisabled = false
      this.loading = false
    },
    saveBasicInfo() {
      if (this.tabs[Number(this.activeName)]?.formId && this.lastIndex !== '0') {
        this.btnDisabled = true
        this.loading = true
        this.type = 'info'
        eval('this.$refs.tab' + this.activeName)[0].Save()
      } else if (this.lastIndex === '0') {
        this.btnDisabled = true
        this.loading = true
        this.type = 'info'
        if (this.visitsStatus.isSucceed) {
          if (this.visitsStatus.isSucceed === '是') {
            this.visitsStatus.incompleteReason = ''
          }
        }
        if (!this.followObj[Number(this.uuid)].dataId) {
          this.visitsStatus.followWindowId = this.followObj[Number(this.uuid)].menuCode
        }
        let params = deepClone(this.visitsStatus)
        this.$api
          .post(
            `/v1/webconsole/follow/save/${this.$route.query.operationId}/${this.$route.query.moduleCode}`,
            {
              ...params
            }
          )
          .then((res) => {
            this.visitsStatus = res.data.data
            this.isEditInfo = deepClone(this.visitsStatus)
            this.isResearch = true
            this.loading = false
            this.$message.success(res.data.msg)
            if (Number(this.activeName) + 1 < this.tabs.length) {
              this.activeName = (Number(this.activeName) + 1).toString()
              this.changeBasicInfo()
            }
            this.scrollToTop()
            this.$bus.$emit('saveFollowFormInfo')
            this.$bus.$emit('getUserFollowInfo')
            this.lastIndex = this.activeName
          })
          .catch(() => {
            this.btnDisabled = false
            this.loading = false
            this.type = 'primary'
          })
      }
    },
    selectRadio(ele, type) {
      if (this.isReadonly) return false
      if (type === 'isSucceed') {
        if (this.visitsStatus.isSucceed == ele.title) {
          return (this.visitsStatus.isSucceed = '')
        }
        if (this.visitsStatus.isSucceed === '否') {
          this.visitsStatus.incompleteReason = null
        }
        this.visitsStatus.isSucceed = ele.title
      }
      if (type === 'incompleteReason') {
        if (this.visitsStatus.incompleteReason == ele.title) {
          return (this.visitsStatus.incompleteReason = '')
        }
        if (this.visitsStatus.incompleteReason !== '死亡') {
          this.visitsStatus.deathDate = null
        }
        this.visitsStatus.incompleteReason = ele.title
      }
      if (type === 'followType') {
        if (this.visitsStatus.followType == ele.title) {
          return (this.visitsStatus.followType = '')
        }
        this.visitsStatus.followType = ele.title
      }
    },
    getLoadEFormDataList(val, type, int) {
      let url = ''
      if (int && int === 'initData') {
        url = `/v1/webconsole/eform/follow/form/${val}`
        this.$api.get(url).then(({ data: res }) => {
          if (res && res.data) {
            this.dataList = [
              {
                content: []
              }
            ]
            this.tabs = [
              {
                alias: '访视状态',
                formName: '访视状态',
                templateId: 'visitsStatus'
              },
              ...res.data
            ]
            const promise = res.data.map(async (item) => {
              let url = `/v1/webconsole/eform/follow/formdata/${val}`
              let data = {
                formId: item.formId
              }
              const res = await this.$api.get(url, data)
              return res.data.data
            })
            Promise.all(promise)
              .then((result) => {
                result.map((item, index) => {
                  if (item.length > 0) {
                    this.dataList.push({
                      ...item[0],
                      examinationInfo: [],
                      content: []
                    })
                  } else {
                    this.dataList.push({
                      formId: res.data[Number(index)].formId,
                      examinationInfo: [],
                      content: []
                    })
                  }
                })
              })
              .then(() => {
                this.getTableData()
              })
              .then(() => {
                this.loading = false
                setTimeout(() => {
                  this.isLock = false
                  this.scrollToTop()
                  if (this.lastIndex !== '0' && type === 'edit') {
                    if (Number(this.activeName) + 1 < this.tabs.length) {
                      this.activeName = (Number(this.activeName) + 1).toString()
                      this.changeBasicInfo()
                    }
                    this.lastIndex = this.activeName
                  }
                }, 500)
              })
          }
        })
      } else {
        url = `/v1/webconsole/eform/follow/window/form/${val}`
        this.$api.get(url).then(({ data: res }) => {
          if (res && res.data) {
            this.dataList = [
              {
                content: []
              }
            ]
            this.tabs = [
              {
                alias: '访视状态',
                formName: '访视状态',
                templateId: 'visitsStatus'
              },
              ...res.data
            ]
            res.data.forEach((item) => {
              this.dataList.push({
                formId: item.formId,
                content: []
              })
            })
            for (let i = 0; i < this.dataList.length; i++) {
              const element = this.dataList[i]
              if (element.formId) {
                let url = '/v1/webconsole/eform/newform'
                let data = {
                  formId: this.dataList[i].formId
                }
                this.$api.get(url, data).then((res) => {
                  if (res.data && res.data.data) {
                    this.$set(element, 'content', res.data.data)
                  }
                })
              }
            }
            this.loading = false
            setTimeout(() => {
              this.isLock = false
              this.scrollToTop()
              if (this.lastIndex !== '0' && type === 'edit') {
                if (Number(this.activeName) + 1 < this.tabs.length) {
                  this.activeName = (Number(this.activeName) + 1).toString()
                  this.changeBasicInfo()
                }
                this.lastIndex = this.activeName
              }
            }, 500)
          }
        })
      }
    },
    getTableData() {
      for (let i = 0; i < this.dataList.length; i++) {
        const element = this.dataList[i]
        if (element.id) {
          let url = `/v1/webconsole/eform/elements/${element.formId}/${element.id}`
          this.$api.get(url).then((res) => {
            if (res.data && res.data.data) {
              this.$set(element, 'content', res.data.data)
            }
          })
        } else if (this.dataList[i].formId) {
          let url = '/v1/webconsole/eform/newform'
          let data = {
            formId: this.dataList[i].formId
          }
          this.$api.get(url, data).then((res) => {
            if (res.data && res.data.data) {
              this.$set(element, 'content', res.data.data)
            }
          })
        }
      }
    },
    // 智能比对
    getIntelligentComparison() {
      this.$router.push({
        path: `followComparison?stage=${this.$route.query.stage}&moduleCode=${this.$route.query.moduleCode}&operationId=${this.$route.query.operationId}`
      })
    },
    // 智能比对
    getIntelligentComparisonBefore() {
      this.$router.push({
        path: `Comparison?moduleCode=${this.$route.query.moduleCode}&operationId=${this.$route.query.operationId}`
      })
    }
  }
}
</script>
<style lang="less">
.el-tooltip__popper {
  max-width: 700px;
}
</style>
<style lang="less" scoped>
.main-content {
  background: #eff0f7;
}
.container {
  .component {
    padding-left: 10px;
    padding-bottom: 20px;
    .list {
      display: flex;
      flex-wrap: wrap;
    }
    .deep {
      width: 100%;
      float: left;
    }

    .basic-div {
      min-width: 30%;
      min-height: 30px;
      float: left;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      font-size: 16px;
      /deep/.editStyle {
        .el-input__inner {
          background-color: #ffffcc;
        }
      }
      /deep/.textareaEditStyle {
        .el-textarea__inner {
          background-color: #ffffcc;
        }
      }
      /deep/ .radioEditStyle {
        .is-checked {
          background-color: #ffffcc;
        }
      }
    }
    /deep/.el-radio__input {
      display: none !important;
    }
    .select-title {
      min-width: 150px;
      white-space: nowrap;
    }
    /deep/.radio {
      word-break: normal;
      .el-radio__input {
        display: none !important;
      }
      .el-checkbox-group,
      .el-radio-group {
        margin: 0;
      }
      .el-input__inner {
        appearance: none;
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 30px;
        line-height: 30px;
        outline: none;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
      }
      .is-bordered {
        padding: 12px 20px 0 10px;
        border-radius: 4px;
        box-sizing: border-box;
        height: 30px;
        line-height: 5px;
      }
      // 日期选择框
      .el-date-editor--date {
        .el-input__inner {
          padding: 0 30px;
        }
      }
      .el-checkbox {
        padding-top: 5px;
      }
      // 只读
      .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
        border-color: #53565c;
      }
      .el-input.is-disabled .el-input__inner {
        background-color: #f5f7fa;
        border-color: #e4e7ed;
        color: #000;
        cursor: not-allowed;
      }
      .el-input .el-input__inner {
        color: #000;
      }
      .el-textarea .el-textarea__inner {
        color: #000;
      }
      .is-disabled .el-textarea__inner::placeholder {
        color: #000;
      }
      /* 谷歌 */
      .is-disabled .el-textarea__inner::-webkit-input-placeholder {
        color: #000;
      }
      /* 火狐 */
      .is-disabled .el-textarea__inner:-moz-placeholder {
        color: #000;
      }
      /*ie*/
      .is-disabled .el-textarea__inner:-ms-input-placeholder {
        color: #000;
      }

      .is-disabled .el-input__inner::placeholder {
        color: #000;
      }
      /* 谷歌 */
      .is-disabled .el-input__inner::-webkit-input-placeholder {
        color: #000;
      }
      /* 火狐 */
      .is-disabled .el-input__inner:-moz-placeholder {
        color: #000;
      }
      /*ie*/
      .is-disabled .el-input__inner:-ms-input-placeholder {
        color: #000;
      }
      .el-checkbox__input.is-disabled + span.el-checkbox__label {
        color: #53565c;
      }
      .el-radio.is-bordered.is-disabled.is-checked {
        border: 2px solid #53565c;
        .el-radio__label {
          color: #53565c;
        }
      }
      .el-radio__input.is-disabled + span.el-radio__label {
        color: #53565c;
      }
      .el-textarea.is-disabled {
        .el-textarea__inner {
          color: #000;
        }
      }
    }
    .div {
      width: 100%;
    }
    /deep/.el-checkbox-group,
    .el-radio-group {
      margin: 0;
    }
    div {
      margin-right: 10px;
    }
    /deep/.el-input__inner {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 30px;
      line-height: 30px;
      outline: none;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }
    /deep/.is-bordered {
      padding: 12px 20px 0 10px;
      border-radius: 4px;
      box-sizing: border-box;
      height: 30px;
      line-height: 5px;
    }
  }

  .input_has_unit {
    margin-left: 0px !important;
  }
  .crt-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .crt-content-left {
      margin-left: 20px;
      height: 28px;
      line-height: 28px;
      width: 55%;
    }
    .crt-content-right {
      display: flex;
      margin-right: 20px;
      height: 28px;
      line-height: 28px;
    }
  }

  .body {
    flex: 1;
    overflow: auto;
    margin-top: 10px;
    margin-left: 10px;
    border-top-left-radius: 8px;
    /deep/.el-card__body {
      padding: 0 0 0 10px;
    }
  }
  .body::-webkit-scrollbar {
    width: 12px;
  }
  .body::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  .body::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  /deep/ .el-tabs {
    margin-top: 5px;
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__active-bar {
      background: #2d507e;
      border: #2d507e;
    }
    .el-tabs__item.is-active {
      color: #2d507e;
    }
    .el-tabs__item:hover {
      color: #427bc0;
    }
    .el-tabs__item {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
    .el-descriptions-item__container {
      margin: 5px 10px;
      .my-label {
        min-width: 120px;
        line-height: 32px;
      }
    }
  }
}
.info-wrap {
  .content {
    .edit-form {
      box-sizing: border-box;
      .base-form {
        /deep/.el-collapse-item__header {
          font-size: 14px;
        }
        /deep/.el-collapse-item__content {
          font-size: 14px;
        }
      }
    }
  }
}
.add-pacs {
  /deep/.el-dialog {
    // width: 1000px !important;
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }
  }
}

.form-wrap {
  margin-top: 80px;
  padding: 0 20px;
  height: 500px;
  overflow-y: auto;

  .adviceTable {
    display: flex;
    margin: 15px 0;
    .adviceTableLabel {
      white-space: nowrap;
      background-color: #aae1fe;
      .adviceTableHeader {
        margin: 1px -1px -1px 1px;
        background-color: #00a5ff;
        border-top: 1px solid #4c6fe2;
        border-bottom: 1px solid #4c6fe2;
        border-left: 1px solid #4c6fe2;
        border-right: 1px solid #4c6fe2;
        height: 30px;
      }
      .adviceTableLabelItem {
        margin: 1px -1px -1px 1px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-bottom: 1px solid #4c6fe2;
        border-right: 1px solid #4c6fe2;
        border-left: 1px solid #4c6fe2;
      }
    }
    .adviceTablePreviewLabel {
      text-align: center;
      min-width: 120px;
      .adviceTableHeader {
        margin: 1px -1px -1px 1px;
        background-color: #00a5ff;
        border-top: 1px solid #4c6fe2;
        border-bottom: 1px solid #4c6fe2;
        border-right: 1px solid #4c6fe2;
        height: 30px;
        line-height: 30px;
        font-weight: bold;
        color: #fff;
      }
      .adviceTableLabelItem {
        margin: 1px -1px -1px 1px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-bottom: 1px solid #4c6fe2;
        border-right: 1px solid #4c6fe2;
      }
    }
    .adviceTableBox {
      display: flex;
      .adviceTableContent {
        min-width: 140px;
        flex-shrink: 0;
        .adviceTableHeader {
          padding: 0 10px;
          margin: 1px -1px -1px 1px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background-color: #00a5ff;
          border-top: 1px solid #4c6fe2;
          border-bottom: 1px solid #4c6fe2;
          border-right: 1px solid #4c6fe2;
        }
        .queryExamToday {
          background-color: #5aff009c !important;
        }
        .adviceTableContentItem {
          margin: 1px -1px -1px 1px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-bottom: 1px solid #4c6fe2;
          border-right: 1px solid #4c6fe2;
          cursor: pointer;
        }
      }

      .cell-pitch_on {
        background-color: #aae1fe;
      }
      .adviceTableContentItem:hover {
        margin: 1px -1px -1px 1px;
        background: #d2eeff;
      }
    }
  }
  .el-divider__text {
    background-color: #f8f8f8;
  }
  .el-row {
    margin-bottom: 15px;
  }
  .el-input {
    width: 250px;
  }
  .el-input__inner {
    border-color: #dcdfe6;
    height: 35px;
  }
  .el-tabs__header {
    position: fixed;
    z-index: 100;
    background: #fff;
  }
}
.auto-leading {
  /deep/.el-dialog {
    width: 30% !important;
    .el-dialog__body {
      height: 200px;
    }
  }
}
</style>
